import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AdminDashboard } from "./components/admin/AdminDashboard";
import { AdminProviders } from "./components/admin/AdminProviders";
import { AdminSettings } from "./components/admin/AdminSettings";
import { AdminUsers } from "./components/admin/users/AdminUsers";
import { AdminMessages } from "./components/admin/messages/AdminMessages";
import { AdminFiles } from "./components/admin/files/AdminFiles";
import { UserDetail } from "./components/admin/users/UserDetail";
import { ChatProviders } from "./components/chat/ChatProviders";
import { RootElement } from "./components/chat/RootElement";
import { VerifyPage } from "./components/login/VerifyPage";
import "./index.css";
import { LoginLayout } from "./layouts/LoginLayout";
import { ChatRoute } from "./routes/ChatRoute";
import { IndexRoute } from "./routes/IndexRoute";

import * as Sentry from "@sentry/react";
import { AdminTools } from "./components/admin/AdminTools";
import { DemoLoginPage } from "./components/login/DemoLoginPage";
import { DemoOnboarding } from "./components/login/DemoOnboarding";
import { LoginPage } from "./components/login/LoginPage";
import { SharedChatPage } from "./routes/SharedChatPage";

const host = import.meta.env.VITE_BACKEND_ENDPOINT;
const wsHost = import.meta.env.VITE_TRPC_ENDPOINT;

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    enabled: !import.meta.env.DEV,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [host, wsHost, "localhost"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootElement />,
    children: [
      {
        path: "/login",
        element: <LoginLayout />,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
          {
            path: "demo",
            element: <DemoLoginPage />,
          },
          {
            path: "check-email",
            element: <VerifyPage />,
          },
          {
            path: "demo-onboarding",
            element: <DemoOnboarding />,
          },
        ],
      },
      {
        path: "/admin",
        element: <AdminProviders />,
        children: [
          {
            index: true,
            element: <AdminDashboard />,
          },
          {
            path: "users",
            element: <AdminUsers />,
          },
          {
            path: "messages",
            element: <AdminMessages />,
          },
          {
            path: "files",
            element: <AdminFiles />,
          },
          {
            path: "settings",
            element: <AdminSettings />,
          },
          {
            path: "users/:userId",
            element: <UserDetail />,
          },
          {
            path: "tools",
            element: <AdminTools />,
          },
        ],
      },
      {
        element: <ChatProviders />,
        children: [
          {
            index: true,
            element: <IndexRoute />,
          },
          {
            path: "chats/:chatId",
            element: <ChatRoute />,
          },
        ],
      },
      {
        path: "share/:chatId",
        element: <SharedChatPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
