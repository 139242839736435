import { ActionIcon, Box, CopyButton, Tooltip } from "@mantine/core";
import { Prism } from "@mantine/prism";
import { IconCopy, IconJson } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { BasicBlock } from "./BasicBlock";
import { IBaseSnippet } from "./types";

const _UnstructuredSnippet = <T extends IBaseSnippet>({ snippet }: { snippet: T }) => {
  const title = snippet.title || "Сырые данные";
  const caption = snippet.caption;

  const code = JSON.stringify(snippet, null, 2);

  return (
    <BasicBlock id={snippet.id} snippet={snippet} title={title} caption={caption} Icon={IconJson}>
      <Box sx={{ position: "relative" }}>
        <Prism language={"json"}>{String(code)}</Prism>
        <CopyButton value={String(code)}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Скопировано" : "Копировать"} position="left">
              <ActionIcon sx={{ position: "absolute", top: 4, right: 4 }} onClick={copy}>
                <IconCopy opacity={0.4} size={20} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Box>
    </BasicBlock>
  );
};

export const UnstructuredSnippet = observer(_UnstructuredSnippet);
