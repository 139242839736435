import { Button, Paper, PaperProps, Text } from "@mantine/core";
import { observer } from "mobx-react";
import { trpc } from "../../utils/trpc";

const PAPER_PROPS: PaperProps = {
  p: "md",
  shadow: "md",
  radius: "md",
  sx: { height: "100%" },
};

const _AdminTools = () => {
  const { mutateAsync, isLoading } = trpc.admin.calculateTokens.useMutation();

  return (
    <div>
      <Paper {...PAPER_PROPS}>
        <Text size="lg" fw={600} mb="md">
          Инструменты администратора
        </Text>
        <Button
          loading={isLoading}
          onClick={() => {
            mutateAsync();
          }}
        >
          Перерасчитать токены
        </Button>
      </Paper>
    </div>
  );
};

export const AdminTools = observer(_AdminTools);
