import {
  IconApi,
  IconBell,
  IconBrandGithub,
  IconDatabase,
  IconDownload,
  IconListCheck,
  IconMessageChatbot,
  IconRobot,
  IconSettings,
  IconShare,
  IconSquareRoot2,
  IconUpload,
  IconVectorTriangle,
  TablerIconsProps,
} from "@tabler/icons-react";

const iconMap: Record<string, React.ComponentType<TablerIconsProps>> = {
  IconRobot,
  IconMessageChatbot,
  IconApi,
  IconSquareRoot2,
  IconVectorTriangle,
  IconDownload,
  IconUpload,
  IconDatabase,
  IconShare,
  IconBell,
  IconSettings,
  IconBrandGithub,
  IconListCheck,
  // Add more icons as needed. All icons were not imported to prevent huge bundle size.
};

export const getIconByName = (name: string) => {
  return iconMap[name];
};
