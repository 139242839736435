import clsx from "clsx";

import { Button, Text, rem } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react";
import { FormEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FinamLogo2 from "../../icons/FinamLogo2";
import { Session, store } from "../../stores/store";
import { client, trpc } from "../../utils/trpc";
import styles from "./LoginPage.module.scss";

interface ILoginPageProps {}

function _DemoLoginPage(props: ILoginPageProps) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const { mutateAsync: demoLogin } = trpc.auth.demoLogin.useMutation();
  const [isLoading, setLoading] = useState(false);

  const handleEmailSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Call an API endpoint to verify the OTP
    try {
      const data = await demoLogin({});

      if (data.success) {
        // put data.token to localStorage
        localStorage.setItem("token", data.token!);
        store.setAuthorized(true);
        const resp = await client.auth.session.query();
        store.setSession(
          new Session({
            email: resp.session.email,
            isAdmin: resp.session.isAdmin,
            needFillInfo: resp.session.needFillInfo,
            userId: resp.session.userId,
          })
        );

        toast.success("Вы успешно вошли", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          navigate("/");
        }, 1500);
        // OTP is correct, proceed to log the user in
        // You might need to use your authentication logic here
      } else {
        setLoading(false);
        toast.error(data.error || "Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Handle the case where the OTP is incorrect
      }
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      // Handle error (e.g., show error message)
    }
  };

  useEffect(() => {
    if (store.isAuthorized()) {
      navigate("/");
    }
  }, [navigate, store.authorized]);

  return (
    <>
      <div style={{ height: "100%", width: "100%", display: "flex" }}>
        <ToastContainer />
        <div className={styles.loginPage}>
          <div style={{ flexGrow: 1 }}></div>
          <div className={clsx("mx-auto", styles.loginPageWrapper)}>
            <div style={{ margin: "0 auto" }}>
              <Link to="/">
                <FinamLogo2 width={200} />
              </Link>
            </div>
            <div className={clsx("flex", "flex-col", "space-y-8")}>
              <h1 className={styles.title}>Финам.AI</h1>
              <form onSubmit={handleEmailSubmit}>
                <Button
                  fullWidth
                  loading={isLoading}
                  type="submit"
                  styles={(theme) => ({
                    root: {
                      fontSize: 20,
                      backgroundColor: "#ff7464",
                      height: rem(48),
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#ff7464", 0.05),
                      }),
                    },
                  })}
                >
                  Поехали!
                </Button>
              </form>
            </div>
          </div>
          <div style={{ maxWidth: 800, textAlign: "center", marginTop: 64, flexGrow: 1 }}>
            <Text color="dimmed">
              Мы не просто предлагаем платформу для общения с Chat GPT – мы создаем экосистему, которая постоянно
              растет, обучается и интегрируется с ключевыми бизнес-процессами и базами данных. Ваш аккаунт будет центром
              управления задачами, автоматизации запросов и получения глубоких аналитических данных.
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}

export const DemoLoginPage = observer(_DemoLoginPage);
