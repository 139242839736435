import { useMantineTheme } from "@mantine/core";
import { observer } from "mobx-react";
import { store } from "../../../../stores/store";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import { ISnippet } from "../../ContentBlock/types";
import styles from "./RichContent.module.css";

interface IProps {
  content: {
    blocks: ISnippet[];
  };
}

const _RichContent = ({ content }: IProps) => {
  const blocks = content.blocks;

  const theme = useMantineTheme();

  return (
    <div className={`${styles.container} ${styles[`rc-${theme.colorScheme}`]}`}>
      {blocks
        .filter((block) => {
          return block.outputType !== "debug" || store.showDebugBlocks;
        })
        .map((task, index) => (
          <ContentBlock key={index} snippet={task} />
        ))}
    </div>
  );
};

export const RichContent = observer(_RichContent);
