import { observer } from "mobx-react";
import { getIconByName } from "../../icons/getIconByName";
import { BasicBlock } from "./BasicBlock";
import { ContentBlock } from "./ContentBlock";
import { IGroupSnippet } from "./types";

const _GroupBlock = ({ snippet }: { snippet: IGroupSnippet }) => {
  return (
    <BasicBlock
      id={snippet.id}
      snippet={snippet}
      type={snippet.type}
      title={snippet.title}
      caption={snippet.caption}
      Icon={getIconByName(snippet.data.iconName || "IconVectorTriangle")}
      iconColor={snippet.data.iconColor ?? undefined}
    >
      {snippet.data.subitems?.length ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          {snippet.data.subitems.map((subitem, index) => (
            <ContentBlock key={index} snippet={subitem} />
          ))}
        </div>
      ) : null}
    </BasicBlock>
  );
};

export const GroupBlock = observer(_GroupBlock);
