import clsx from "clsx";

import { Button, Text, rem } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react";
import { FormEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FinamLogo2 from "../../icons/FinamLogo2";
import { store } from "../../stores/store";
import { trpc } from "../../utils/trpc";
import styles from "./LoginPage.module.scss";

interface ILoginPageProps {}

function _DemoOnboarding(props: ILoginPageProps) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const { mutateAsync: demoOnboardingSendOtp, isLoading } = trpc.auth.demoOnboardingSendOtp.useMutation();

  const handleEmailSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    // Call an API endpoint to send the OTP to the user's email
    try {
      const data = await demoOnboardingSendOtp({ email });

      if (data.success) {
        // redirect to /verify?email=${email}
        navigate(`/login/check-email?email=${encodeURIComponent(email)}&needinfo=${data.shouldFillInfo ? "1" : "0"}`);
      } else {
        toast.error(data.error || "Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Handle any errors like email not sent or service not available
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Произошла ошибка", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (store.authFinished && !store.authorized) {
      localStorage.removeItem("token");
      navigate("/login");
      return;
    }
  }, [store.authFinished, store.authorized, store.session]);

  return (
    <>
      <div style={{ height: "100%", width: "100%", display: "flex" }}>
        <ToastContainer />
        <div className={styles.loginPage}>
          <div style={{ flexGrow: 1 }}></div>
          <div className={clsx("mx-auto", styles.loginPageWrapper)}>
            <div style={{ margin: "0 auto" }}>
              <Link to="/">
                <FinamLogo2 width={200} />
              </Link>
            </div>
            <div className={clsx("flex", "flex-col", "space-y-8")}>
              <h1 className={styles.title}>Вход</h1>
              <p className={styles.caption}>Для продолжения пользования сервисом пожалуйста пройдите авторизацию.</p>
              <form onSubmit={handleEmailSubmit}>
                <input
                  required
                  type="email"
                  id="email"
                  name="email"
                  className={styles.emailInput}
                  placeholder="Корпоративный Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  fullWidth
                  loading={isLoading}
                  type="submit"
                  styles={(theme) => ({
                    root: {
                      fontSize: 20,
                      backgroundColor: "#ff7464",
                      height: rem(48),
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#ff7464", 0.05),
                      }),
                    },
                  })}
                >
                  Получить код
                </Button>
              </form>
            </div>
          </div>
          <div style={{ maxWidth: 800, textAlign: "center", marginTop: 64, flexGrow: 1 }}>
            <Text color="dimmed">
              Мы не просто предлагаем платформу для общения с Chat GPT – мы создаем экосистему, которая постоянно
              растет, обучается и интегрируется с ключевыми бизнес-процессами и базами данных. Ваш аккаунт будет центром
              управления задачами, автоматизации запросов и получения глубоких аналитических данных.
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}

export const DemoOnboarding = observer(_DemoOnboarding);
