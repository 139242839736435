import { ActionIcon } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { store } from "../../../stores/store";

interface IAttachedFile {
  fileId: string;
  filename: string;
}
interface IProps {
  file: IAttachedFile;
}

export function AttachedFile(props: IProps) {
  const { file } = props;

  const extension = file.filename.split(".").pop()?.toUpperCase();

  return (
    <div
      style={{
        alignSelf: "end",
        display: "flex",
        border: "1px solid #373A40",
        borderRadius: 4,
        padding: 8,
        paddingRight: 16,
        alignItems: "center",
        lineHeight: 1.2,
        fontSize: 12,
        backgroundColor: "#25262B",
      }}
    >
      <div>
        <div
          style={{
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: 150,
          }}
        >
          {file.filename}
        </div>
        <div>{extension}</div>
      </div>

      <div style={{ top: -40, right: -3, position: "relative" }}>
        <ActionIcon
          style={{ position: "absolute" }}
          onClick={() => {
            store.setAttachedFiles(store.attachedFiles.filter((f) => f.fileId !== file.fileId));
          }}
        >
          <IconX size="0.875rem" style={{ height: 16, width: 16 }} color="#ff4d4d" />
        </ActionIcon>
      </div>
    </div>
  );
}
