import { Button, Group, NumberInput, Paper, Skeleton, Space, Stack, Switch, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { client, trpc } from "../../../utils/trpc";

const _UserDetail = () => {
  const navigate = useNavigate();

  const { mutate: updateUser } = trpc.admin.updateUser.useMutation();

  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [requestLimit, setRequestLimit] = useState<number | null>(null);
  const [inputCharLimit, setInputCharLimit] = useState<number | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [canUpdateIsAdmin, setCanUpdateIsAdmin] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    async function fetchUser() {
      if (!userId) {
        return;
      }
      const data = await client.admin.getUser.query({ userId: Number(userId) });
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.email);
      setRequestLimit(data.user.requestLimit);
      setInputCharLimit(data.user.inputCharLimit);
      setIsAdmin(data.user.isAdmin);
      setDisabled(data.user.disabled);
      setCanUpdateIsAdmin(data.user.canUpdateIsAdmin);
      setIsLoading(false);
    }
    fetchUser();
  }, []);

  if (isLoading) {
    return (
      <Paper>
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </Paper>
    );
  }

  return (
    <Paper p="xl">
      <Button
        variant="light"
        onClick={() => {
          navigate(-1);
        }}
      >
        Назад
      </Button>
      <Space h={"xs"} />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateUser(
            {
              userId: Number(userId),
              firstName,
              lastName,
              email,
              dailyLimit: requestLimit,
              isAdmin,
              disabled,
              inputCharLimit,
            },
            {
              onSuccess: () => {
                notifications.show({
                  message: "Пользователь успешно обновлён",
                  color: "teal",
                });
              },
            }
          );
        }}
      >
        <Group>
          <TextInput label="Имя" required value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
          <TextInput label="Фамилия" required value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
          <TextInput label="Email" required value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
        </Group>
        <Space h={"md"} />
        <Stack w={450}>
          <Switch
            label="Заблокировать доступ"
            checked={disabled}
            onChange={(e) => {
              setDisabled(e.target.checked);
            }}
          />
          <Switch
            label="Права администратора"
            checked={isAdmin}
            disabled={!canUpdateIsAdmin}
            onChange={(e) => {
              setIsAdmin(e.target.checked);
            }}
          />
          <Switch
            label="Индивидуальный лимит на кол-во запросов в день"
            checked={requestLimit !== null}
            onChange={(e) => {
              setRequestLimit(e.target.checked ? 50 : null);
            }}
          />
          {requestLimit !== null && (
            <NumberInput
              required
              label="Лимит"
              type="number"
              value={requestLimit}
              onKeyDown={(evt) => {
                return (evt.key === "e" || evt.key === "-") && evt.preventDefault();
              }}
              onChange={(e) => {
                if (/^\d*$/.test(e.toString())) {
                  setRequestLimit(+e);
                }
              }}
            />
          )}
          <Switch
            label="Индивидуальный лимит на кол-во символов в запросе"
            checked={inputCharLimit !== null}
            onChange={(e) => {
              setInputCharLimit(e.target.checked ? 4000 : null);
            }}
          />
          {inputCharLimit !== null && (
            <NumberInput
              required
              label="Лимит"
              type="number"
              max={120000}
              value={inputCharLimit}
              onKeyDown={(evt) => {
                return (evt.key === "e" || evt.key === "-") && evt.preventDefault();
              }}
              onChange={(e) => {
                if (/^\d*$/.test(e.toString())) {
                  setInputCharLimit(+e);
                }
              }}
            />
          )}
          <Button type="submit">Сохранить</Button>
        </Stack>
      </form>
    </Paper>
  );
};

export const UserDetail = observer(_UserDetail);
