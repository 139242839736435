import { ActionIcon, Header, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlignLeft, IconCode, IconPhoto, IconShare2 } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useState } from "react";
import { useChatId } from "../../hooks/useChatId";
import { store } from "../../stores/store";
import { ShareChatModal } from "../ShareChatModal";
import ModelSelect from "./ModelSelect/ModelSelect";
import { sendImageModelChangeMetrics } from "../../utils/sendImageModelChangeMetrics";
import { IconBrandHipchat } from "@tabler/icons-react";
import { IconParentheses } from "@tabler/icons-react";

export const HEADER_HEIGHT = 60;

const _ChatHeader = () => {
  const chatId = useChatId();
  const chat = chatId ? store.getChatById(chatId) : null;
  const theme = useMantineTheme();

  const [shareOpened, setShareOpened] = useState(false);
  const matches = useMediaQuery("(min-width:70em)");

  return (
    <>
      <Header height={HEADER_HEIGHT} p="xs" className="app-region-drag" style={{ display: "flex" }}>
        {store.appConfig.textModels.length > 1 && !["FLOW", "AI_CODER", "AI_LOCAL", "ANONYMIZER"].includes(chat?.type || "") && (
          <ModelSelect
            value={chat ? store.appConfig.textModels.find((x) => x.value === chat.type)?.value || "" : store.textModel}
            disabled={!!chat}
            options={store.appConfig.textModels}
            onChange={store.setTextModel}
            Icon={IconAlignLeft}
          />
        )}
        {store.appConfig.imageModels.length > 1 && !["FLOW", "AI_CODER", "AI_LOCAL", "ANONYMIZER"].includes(chat?.type || "") && (
          <ModelSelect
            value={store.imageModel}
            options={store.appConfig.imageModels}
            onChange={(newImageModel) => {
                const prevImageModel = localStorage.getItem("imageModel");
                store.setImageModel(newImageModel)
                sendImageModelChangeMetrics(prevImageModel, newImageModel)
              }
            }
            Icon={IconPhoto}
          />
        )}
        {store.appConfig.codeModels.length > 1 && chat?.type === "AI_CODER" && (
          <ModelSelect
            tagColor={theme.colorScheme === "dark" ? theme.colors.brand[8] : theme.colors.brand[6]} 
            value={store.codeModel}
            options={store.appConfig.codeModels}
            onChange={(newCodeModel) => {
                store.setCodeModel(newCodeModel)
              }
            }
            Icon={IconCode}
          />
        )}
        {store.appConfig.localModels.length > 1 && chat?.type === "AI_LOCAL" && (
          <ModelSelect
            tagColor={theme.colorScheme === "dark" ? theme.colors.brand[8] : theme.colors.brand[6]}
            value={store.localModel}
            options={store.appConfig.localModels}
            onChange={(newLocalModel) => {
                store.setLocalModel(newLocalModel)
              }
            }
            Icon={IconBrandHipchat}
          />
        )}
        {store.appConfig.anonymizerMethods.length > 1 && chat?.type === "ANONYMIZER" && (
          <ModelSelect
            value={store.anonymizerMethod}
            options={store.appConfig.anonymizerMethods}
            onChange={(newAnonymizerMethod) => {
                store.setAnonymizerMethod(newAnonymizerMethod)
              }
            }
            Icon={IconParentheses}
          />
        )}
        {chat && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: matches ? "auto" : undefined,
            }}
          >
            <ShareChatModal opened={shareOpened} onClose={() => setShareOpened(false)} />
            {!!chat?.canShare && (
              <ActionIcon
                style={{ marginLeft: 32 }}
                loading={false}
                onClick={async () => {
                  setShareOpened(true);
                }}
              >
                <IconShare2 />
              </ActionIcon>
            )}
          </div>
        )}
      </Header>
    </>
  );
};

const ChatHeader = observer(_ChatHeader);
export default ChatHeader;
