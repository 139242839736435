import { observer } from "mobx-react";
import { MessageContent } from "../MessageItem/MessageContent";
import { IMarkdownSnippet } from "./types";

const _MarkdownBlock = ({ snippet }: { snippet: IMarkdownSnippet }) => {
  const title = snippet.title;
  const caption = snippet.caption;

  return <MessageContent content={snippet.data.content} />;
};

export const MarkdownBlock = observer(_MarkdownBlock);
