import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../stores/store";
import LoadingScreen from "../LoadingScreen";
import { ConfigProvider } from "./ConfigProvider";

interface IProps {
  children: React.ReactNode;
}

function _AuthWrapper(props: IProps) {
  const { children } = props;

  const navigate = useNavigate();

  useEffect(() => {
    if (store.authFinished && !store.authorized) {
      navigate("/login");
      return;
    }
    if (store.authFinished && store.authorized && store.session?.needFillInfo) {
      navigate("/login/demo-onboarding");
      return;
    }
  }, [store.authFinished, store.authorized, store.session]);

  const loading = !store.connected || !store.authorized || !store.session;

  return (
    <LoadingScreen loading={loading}>
      <ConfigProvider>{children}</ConfigProvider>
    </LoadingScreen>
  );
}

export const AuthWrapper = observer(_AuthWrapper);
