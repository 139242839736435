export function ArrowUpIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-md"
      style={{
        display: "block",
        verticalAlign: "middle",
        width: 18,
        height: 18,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289L18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071C18.3166 15.0976 17.6834 15.0976 17.2929 14.7071L12 9.41421L6.70711 14.7071C6.31658 15.0976 5.68342 15.0976 5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929L11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
