import { ActionIcon, Box, CopyButton, Tooltip } from "@mantine/core";
import { Prism } from "@mantine/prism";
import { IconCopy } from "@tabler/icons-react";
import { Language } from "prism-react-renderer";

interface IProps {
  code: string;
  language: Language;
}

export function CodeBox({ code, language }: IProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <Prism language={language}>{String(code)}</Prism>
      <CopyButton value={String(code)}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? "Скопировано" : "Копировать"} position="left">
            <ActionIcon sx={{ position: "absolute", top: 4, right: 4 }} onClick={copy}>
              <IconCopy opacity={0.4} size={20} />
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton>
    </Box>
  );
}
