import { observer } from "mobx-react";
import { Message, store } from "../../stores/store";
import { trpc } from "../../utils/trpc";
import { IChatUpdate, IChatUpdateAdd, IChatUpdateDelete } from "../../../../backend/src/router/chat/chatUpdate.emitter";

function isChatUpdateAdd(update: IChatUpdate): update is IChatUpdateAdd {
  return update.eventType === "add";
}

function isChatUpdateDelete(update: IChatUpdate): update is IChatUpdateDelete {
  return update.eventType === "delete";
}

function _ChatSubscription() {
  const { mutate: generateChatTitle } = trpc.chat.generateChatTitle.useMutation();

  trpc.chat.onChatUpdate.useSubscription(undefined, {
    onData(chatUpdate) {
      const { messageId, submitting } = chatUpdate;

      if (isChatUpdateDelete(chatUpdate)) {
        store.deleteMessageById(messageId);
        if (submitting !== undefined) {
          store.setSubmitting(submitting);
        }
        return null;
      }

      let updatedMessage = store.messages.find((message) => message.id === messageId);
      if (!updatedMessage) {
        updatedMessage = new Message({
          id: chatUpdate.messageId,
          chatId: chatUpdate.chatId,
          role: chatUpdate.role,
          createdAt: new Date(),
          content: !chatUpdate.isFinal ? `${chatUpdate.content}█` : chatUpdate.content,
          richContent: chatUpdate.richContent,
          toolUsed: chatUpdate.toolUsed,
          temp: chatUpdate.temp,
          attachedFiles: chatUpdate.attachedFiles || [],
        });
        store.addMessage(updatedMessage);
      }
      updatedMessage?.setState({
        content: !chatUpdate.isFinal ? `${chatUpdate.content}${!chatUpdate.toolUsed ? "█" : ""}` : chatUpdate.content,
        richContent: chatUpdate.richContent,
        toolUsed: chatUpdate.toolUsed,
        temp: chatUpdate.temp,
      });
      if (submitting !== undefined) {
        store.setSubmitting(submitting);
      }

      const chat = store.getChatById(chatUpdate.chatId);

      if (!["FLOW", "AI_CODER", "AI_LOCAL", "ANONYMIZER"].includes(chat?.type || "") && chatUpdate.isFinal) {
        const chatMessagesCount = store.getChatMessages(chatUpdate.chatId).length;
        if (chatMessagesCount === 2 && !updatedMessage?.temp) {
          generateChatTitle(
            { chatId: chatUpdate.chatId },
            {
              onSuccess: (data) => {
                store.getChatById(chatUpdate.chatId)?.setState({ description: data.title });
              },
            }
          );
        }
      }
    },
  });

  return null;
}

export const ChatSubscription = observer(_ChatSubscription);
