import { Button, ColorScheme, Modal, Select, Stack, useMantineColorScheme } from "@mantine/core";
import { observer } from "mobx-react";
import { store } from "../../../stores/store";

interface IProps {
  opened: boolean;
  onClose(): void;
}

const _SettingsModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <>
      <Modal opened={opened} onClose={onClose} title="Настройки">
        <Stack>
          <Select
            label="Тема"
            value={colorScheme}
            onChange={(value) => {
              toggleColorScheme(value as ColorScheme);
            }}
            data={[
              { label: "Светлая", value: "light" },
              { label: "Тёмная", value: "dark" },
            ]}
          />
          <Select
            label="Показывать отладочные блоки в Finam Flow?"
            value={(store.showDebugBlocks ?? false).toString()}
            onChange={(value) => {
              store.setShowDebugBlocks(value === "true");
            }}
            data={[
              { label: "Нет", value: "false" },
              { label: "Да", value: "true" },
            ]}
          />
          <Button onClick={onClose} style={{ marginTop: 64 }}>
            Закрыть
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export const SettingsModal = observer(_SettingsModal);
