import { Paper, PaperProps, Text } from "@mantine/core";
import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";

const PAPER_PROPS: PaperProps = {
  p: "md",
  shadow: "md",
  radius: "md",
  sx: { height: "100%" },
};

const _AdminDashboard = () => {
  return <Navigate to="/admin/users" />;
  return (
    <div>
      <Paper {...PAPER_PROPS}>
        <Text>test</Text>
      </Paper>
    </div>
  );
};

export const AdminDashboard = observer(_AdminDashboard);
