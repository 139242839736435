import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Button,
  Center,
  Header,
  MediaQuery,
  Navbar,
  Tooltip,
  createStyles,
  getStylesRef,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconLogout, IconMessage, IconFile, IconMoonStars, IconSettings, IconSunHigh, IconUsers } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useChatId } from "../../hooks/useChatId";
import FinamLogo2 from "../../icons/FinamLogo2";
import { store } from "../../stores/store";
import { trpc } from "../../utils/trpc";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },
  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    },
  },
  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      },
    },
  },
}));

const data = [
  {
    link: "/admin/users",
    label: "Пользователи",
    icon: IconUsers,
    isActive: (pathname: string) => {
      return pathname.startsWith("/admin/users");
    },
  },
  {
    link: "/admin/messages",
    label: "Сообщения",
    icon: IconMessage,
    isActive: (pathname: string) => {
      return pathname.startsWith("/admin/messages");
    },
  },
  {
    link: "/admin/files",
    label: "Файлы",
    icon: IconFile,
    isActive: (pathname: string) => {
      return pathname.startsWith("/admin/files");
    },
  },
  {
    link: "/admin/settings",
    label: "Системные настройки",
    icon: IconSettings,
    isActive: (pathname: string) => {
      return pathname.startsWith("/admin/settings");
    },
  },
];

export default function DashboardNavbar({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");

  const navigate = useNavigate();

  const location = useLocation();
  const links = data.map((item) => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.isActive(location.pathname) })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));
  const theme = useMantineTheme();
  const border = `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`;
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { mutateAsync: logout } = trpc.auth.logout.useMutation();

  return (
    <Navbar width={{ md: 300 }} hiddenBreakpoint="md" hidden={!opened}>
      <Navbar.Section className="app-region-drag">
        <Box
          style={{
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: border,
          }}
        >
          <Link
            to="/"
            className="app-region-no-drag"
            style={{
              marginTop: 10,
              padding: 4,
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <FinamLogo2
              style={{
                height: 22,
                color: "#ffc755",
                display: "block",
                marginRight: 8,
              }}
            />
            <span
              style={{
                fontSize: 20,
                color: "#ffc755",
                display: "block",
                textDecoration: "none",
              }}
            >
              Финам.AI
            </span>
          </Link>
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={() => setOpened(!opened)}
              size="sm"
              color={theme.colors.gray[6]}
              className="app-region-no-drag"
              sx={{ position: "fixed", right: 16 }}
            />
          </MediaQuery>
        </Box>
      </Navbar.Section>
      <Navbar.Section grow>{links}</Navbar.Section>
      <Navbar.Section sx={{ borderTop: border }} p="xs">
        <Center>
          <Button
            variant="light"
            fullWidth
            onClick={() => {
              navigate("/");
            }}
          >
            Вернуться в чат
          </Button>
        </Center>
      </Navbar.Section>
      <Navbar.Section sx={{ borderTop: border }} p="xs">
        <Center>
          <Tooltip label={colorScheme === "dark" ? "Светлая тема" : "Тёмная тема"}>
            <ActionIcon sx={{ flex: 1 }} size="xl" onClick={() => toggleColorScheme()}>
              {colorScheme === "dark" ? <IconSunHigh size={20} /> : <IconMoonStars size={20} />}
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Выйти">
            <ActionIcon
              sx={{ flex: 1 }}
              size="xl"
              onClick={() => {
                logout()
                  .then(() => {
                    localStorage.removeItem("token");
                    store.setAuthorized(false);
                    window.TxGlobalAuth.logout()
                      .then(() => {
                        window.location.reload();
                      })
                  })
                  .catch((err) => {
                    console.error(err);
                    notifications.show({
                      title: "Ошибка",
                      message: "Произошла ошибка при выходе",
                      color: "red",
                    });
                  });
              }}
            >
              <IconLogout size={20} />
            </ActionIcon>
          </Tooltip>
        </Center>
      </Navbar.Section>
    </Navbar>
  );
}

interface IProps {
  children: React.ReactNode;
}

const _AdminLayout = ({ children }: IProps) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [tab, setTab] = useState<"Chats" | "Prompts">("Chats");
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const location = useLocation();
  const search = store.search;
  const chatId = useChatId();

  const chat = chatId ? store.getChatById(chatId) : null;

  const border = `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`;

  useEffect(() => {
    setOpened(false);
  }, [location.pathname]);

  return (
    <AppShell
      /* className={`${colorScheme}-theme`} */
      navbarOffsetBreakpoint="sm"
      navbar={
        <DashboardNavbar
          opened={opened}
          setOpened={(value) => {
            setOpened(value);
          }}
        />
      }
      header={
        <Header height={60}>
          <div></div>
        </Header>
      }
      layout="alt"
    >
      <MediaQuery largerThan="md" styles={{ display: "none" }}>
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size="sm"
          color={theme.colors.gray[6]}
          className="app-region-no-drag"
          sx={{ position: "fixed", top: 16, right: 16, zIndex: 100 }}
        />
      </MediaQuery>
      {children}
    </AppShell>
  );
};

export const AdminLayout = observer(_AdminLayout);
