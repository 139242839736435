import { ActionIcon, Modal, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBug, TablerIconsProps } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useChatId } from "../../../hooks/useChatId";
import { store } from "../../../stores/store";
import { CodeBox } from "../../CodeBox/CodeBox";
import { ArrowDownIcon } from "../../icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../icons/ArrowUpIcon";
import styles from "./MessageBlock.module.css";
import { IBaseSnippet } from "./types";

interface IProps {
  id: string;
  iconColor?: string;
  snippet: IBaseSnippet;
  type?: string;
  title?: string;
  caption?: string;
  children: React.ReactNode | null;
  Icon?: React.ComponentType<TablerIconsProps>;
}

const _BasicBlock = (props: IProps) => {
  const chatId = useChatId();

  const chat = chatId ? store.getChatById(chatId) : null;
  const theme = useMantineTheme();

  const [isExpanded, setIsExpanded] = useState<boolean>(chat?.getBlockExpanded(props.id) || false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("0px");
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [opened, { toggle, close }] = useDisclosure(false);

  const toggleTaskDetails = () => {
    if (!props.children) {
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    chat?.setBlockExpanded(props.id, !isExpanded);
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        setHeight(`${contentRef.current.scrollHeight}px`);
        timerRef.current = setTimeout(() => {
          setHeight("auto");
        }, 300);
      } else {
        setHeight("0px");
      }
    }
  }, [isExpanded]);

  const Icon = props.Icon;

  return (
    <div className={`${styles.container} ${styles[`rc-${theme.colorScheme}`]}`}>
      <Modal opened={opened} withCloseButton onClose={close} size="xl" radius="md">
        <CodeBox code={JSON.stringify(props.snippet, null, 2)} language="json" />
      </Modal>
      <div className={`${styles.snippet}`} onClick={toggleTaskDetails}>
        {Icon ? (
          <Icon style={{ marginRight: 16 }} color={props.iconColor} />
        ) : (
          <button className={styles.taskLabel} aria-expanded={isExpanded} />
        )}
        <div className={styles.snippetHeader}>
          <div className={styles.snippetTitle}>
            <span>{props.title}</span>
            {!!props.children && <>{isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}</>}
          </div>
          <div className={styles.caption}>
            <ReactMarkdown children={props.caption || ""} />
          </div>
        </div>
        {!!props.snippet && (
          <div style={{ marginLeft: "auto" }}>
            <ActionIcon
              variant="filled"
              onClick={(event) => {
                event.stopPropagation();
                toggle();
              }}
            >
              <IconBug />
            </ActionIcon>
          </div>
        )}
      </div>
      <div
        ref={contentRef}
        style={{
          height: height,
          overflow: "hidden",
          transition: "height 0.3s ease, opacity 0.3s ease, transform 0.3s ease",
          opacity: isExpanded ? 1 : 0,
          transform: isExpanded ? "translateY(0)" : "translateY(-20px)",
          padding: isExpanded ? 16 : 0,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export const BasicBlock = observer(_BasicBlock);
