import { Container, Stack } from "@mantine/core";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Message, store } from "../../stores/store";
import { IMessage } from "../../stores/types";
import { trpc } from "../../utils/trpc";
import { MessageItem } from "./MessageItem/MessageItem";
import { PromptInput } from "./PromptInput/PromptInput";
import { ChatsBanner } from "./ChatsBanner";

interface IProps {
  chatId: string;
}

const _ChatContainer = (props: IProps) => {
  const { chatId } = props;

  const messages = chatId ? store.getVisibleChatMessages(chatId) : [];

  const { refetch: getMessages } = trpc.chat.getMessages.useQuery({ chatId }, {});
  const navigate = useNavigate();

  useEffect(() => {
    const chat = store.getChatById(chatId);

    async function fetchMessages() {
      const resp = await getMessages();
      if (resp.data?.length) {
        for (const msg of resp.data) {
          store.addMessage(
            new Message({
              id: msg.id,
              createdAt: new Date(),
              chatId,
              role: msg.role as IMessage["role"],
              content: msg.content,
              richContent: msg.richContent,
              hidden: msg.hidden,
              attachedFiles: msg.attachedFiles,
            })
          );
        }
      } else {
        if (!["FLOW", "AI_CODER", "AI_LOCAL", "ANONYMIZER"].includes(chat?.type || "")) {
          navigate("/");
        }
        return;
      }
    }

    if (chat) {
      store.setTextModel(chat.type)

      if (!chat.chatLoaded) {
        chat?.setState({ chatLoaded: true });
        fetchMessages();
      }
    }
  }, [chatId, store.chatsLoaded]);

  const [isAtBottom, setIsAtBottom] = useState<boolean>(true);

  const handleScroll = (): void => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;
    const atBottom = scrollTop + clientHeight >= scrollHeight;
    setIsAtBottom(atBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ChatsBanner/>
      <Container pt="xl" pb={120}>
        <Stack spacing="xs">
          {messages.map((message, index) => (
            <MessageItem key={message.id} message={message} autoscroll={index === messages.length - 1 && isAtBottom} />
          ))}
        </Stack>
      </Container>
      <PromptInput />
    </>
  );
};

export const ChatContainer = observer(_ChatContainer);
