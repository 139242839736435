import { ActionIcon, Anchor, Box, Code, CopyButton, Table, Tooltip, useMantineTheme } from "@mantine/core";
import { Prism, PrismProps } from "@mantine/prism";
import { IconCopy } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useChatId } from "../../../hooks/useChatId";
import { store } from "../../../stores/store";
import { getIconByName } from "../../icons/getIconByName";
import styles from "./MessageContent.module.css";

const DARK_PRISM_COLOR = "#141517";
const LIGHT_PRISM_COLOR = "#f5f7f9";

const _MessageContent = ({ content, autoscroll }: { content: string | null; autoscroll?: boolean }) => {
  const chatId = useChatId();
  const theme = useMantineTheme();
  const [codeBackgroundColor, setCodeBackgroundColor] = useState(
    theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1]
  );
  const [prismBackgroundColor, setPrismBackgroundColor] = useState(
    theme.colorScheme === "dark" ? DARK_PRISM_COLOR : LIGHT_PRISM_COLOR
  );

  useEffect(() => {
    if (autoscroll) {
      window.scrollTo(0, document.documentElement.scrollHeight);
    }
  }, [content, autoscroll]);

  useEffect(() => {
    setCodeBackgroundColor(theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1]);
    setPrismBackgroundColor(theme.colorScheme === "dark" ? DARK_PRISM_COLOR : LIGHT_PRISM_COLOR);
  }, [theme.colorScheme]);

  if (!content) {
    return null;
  }

  return (
    <ReactMarkdown
      children={content || ""}
      remarkPlugins={[remarkGfm]}
      components={{
        table: ({ node, ...props }) => <Table verticalSpacing="sm" highlightOnHover {...props} />,
        img: ({ node, ...props }) => {
          const src = props.src?.startsWith("file-service://")
            ? `${props.src.replace("file-service://", `${import.meta.env.VITE_BACKEND_ENDPOINT}/images/`)}`
            : props.src;
          return (
            <div style={{ maxWidth: 400 }}>
              <a href={src} download target="_blank">
                <img style={{ width: "100%" }} src={src} />
              </a>
            </div>
          );
        },
        code: ({ node, inline, ...props }) => {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          const language = (match?.[1] || "python") as PrismProps["language"];

          if (inline) return <Code {...props} />;

          const isText = (language as string) === "text";
          return (
            <Box
              sx={{
                position: "relative",
                backgroundColor: isText ? codeBackgroundColor : prismBackgroundColor,
                borderRadius: "0.25rem",
              }}
            >
              {isText ? (
                <Code
                  block
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    paddingRight: "30px",
                  }}
                >
                  {String(children)}
                </Code>
              ) : (
                <Prism noCopy sx={{ paddingRight: "30px" }} language={language}>
                  {String(props.children)}
                </Prism>
              )}
              <CopyButton value={String(props.children)}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Скопировано" : "Копировать"} position="left">
                    <ActionIcon sx={{ position: "absolute", top: 2, right: 2 }} onClick={copy}>
                      <IconCopy opacity={0.4} size={20} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Box>
          );
        },
        a: ({ node, ...props }) => {
          const nodeVal = "value" in node.children[0] ? node.children[0].value : null;
          const href = typeof node.properties?.href === "string" ? node.properties.href : null;

          if (href?.startsWith("flow://")) {
            return (
              <a
                onClick={(event) => {
                  const prompt = `Запрашиваю [${nodeVal}](${href})`;
                  event.preventDefault();
                  store.getCompletion({
                    prompt,
                    chatId: chatId,
                  });
                }}
                href="#"
              >
                {props.children}
              </a>
            );
          }

          if (href?.startsWith("rc-link://")) {
            const url = new URL(href);
            const linkUrl = url.searchParams.get("url") || "#";
            const iconName = url.searchParams.get("iconName");
            const iconColor = url.searchParams.get("iconColor");
            const Icon = iconName ? getIconByName(iconName) : null;

            return (
              <Anchor rel="noopener noreferrer" target="_blank" href={linkUrl} className={styles.styledLink}>
                {Icon ? (
                  <Icon height={"1rem"} className={styles.linkIcon} style={{ color: iconColor || undefined }} />
                ) : null}
                {props.children}
              </Anchor>
            );
          }

          return <a {...props} target="_blank" rel="noopener noreferrer" />;
        },
      }}
    />
  );
};

export const MessageContent = observer(_MessageContent);
