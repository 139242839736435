import { observer } from "mobx-react";
import { useEffect } from "react";
import { store } from "../../stores/store";
import LoadingScreen from "../LoadingScreen";

interface IProps {
  children: React.ReactNode;
}

function _ConfigProvider(props: IProps) {
  useEffect(() => {
    store.loadConfig();
  }, []);

  return (
    <>
      <LoadingScreen loading={!store.configLoaded}>{props.children}</LoadingScreen>
    </>
  );
}

export const ConfigProvider = observer(_ConfigProvider);
