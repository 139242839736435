import { observer } from "mobx-react";
import { GroupBlock } from "./GroupBlock";
import { MarkdownBlock } from "./MarkdownBlock";
import { MeetingBlock } from "./MeetingBlock";
import { TaskBlock } from "./TaskBlock";
import { ISnippet } from "./types";
import { UnstructuredSnippet } from "./UnstructuredBlock";

const _ContentBlock = ({ snippet }: { snippet: ISnippet }) => {
  if (snippet.type === "group") {
    return <GroupBlock snippet={snippet} />;
  }

  if (snippet.type === "meeting") {
    return <MeetingBlock snippet={snippet} />;
  }

  if (snippet.type === "task") {
    return <TaskBlock snippet={snippet} />;
  }

  if (snippet.type === "markdown") {
    return <MarkdownBlock snippet={snippet} />;
  }

  // @ts-ignore
  return <UnstructuredSnippet snippet={snippet} />;
};

export const ContentBlock = observer(_ContentBlock);
