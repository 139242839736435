import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colors: {
    brand: [
      "#fff8e1",
      "#ffefcc",
      "#ffde9b",
      "#ffcc64",
      "#ffbd38",
      "#ffb31b",
      "#ffae09",
      "#e39800",
      "#ca8700",
      "#af7300",
    ],
  },
  primaryColor: "brand",
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0],
    },
  }),
  components: {
    Modal: {
      defaultProps: {
        padding: "xl",
      },
      styles: {
        title: {
          fontSize: "1.2rem",
          fontWeight: 600,
        },
      },
    },
    ModalRoot: {
      defaultProps: {
        centered: true,
      },
    },
    Overlay: {
      defaultProps: {
        opacity: 0.6,
        blur: 6,
      },
    },
    // Input: {
    //   defaultProps: {
    //     variant: "filled",
    //   },
    // },
    InputWrapper: {
      styles: {
        label: {
          marginBottom: 4,
        },
      },
    },
    Code: {
      styles: (theme) => ({
        root: {
          fontSize: theme.fontSizes.sm,
          backgroundColor: theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.colors.gray[1],
        },
      }),
    },
  },
};
