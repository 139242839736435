import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RouterOutputs, trpc } from "../../../utils/trpc";

type User = RouterOutputs["admin"]["listUsers"]["users"][0];

const UsersTable = () => {
  const navigate = useNavigate();

  const { data } = trpc.admin.listUsers.useQuery({});

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "id", //access nested data with dot notation
        header: "ID",
      },
      {
        accessorKey: "firstName", //access nested data with dot notation
        header: "Имя",
      },
      {
        accessorKey: "lastName",
        header: "Фамилия",
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        enableSorting: false,
      },
      {
        accessorKey: "isAdmin", //normal accessorKey
        header: "Администратор?",
        enableSorting: false,
        Cell: ({ cell }) => {
          return cell.getValue<boolean>() ? "Да" : "Нет";
        },
      },
      {
        accessorKey: "userLimit",
        header: "Лимит запросов",
      },
      {
        accessorKey: "requestsToday",
        header: "Запросов (за сегодня)",
      },
      {
        accessorKey: "remaining",
        header: "Осталось запросов (сегодня)",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    mantineTableBodyRowProps: (props) => {
      return {
        onClick: () => {
          navigate(`/admin/users/${props.row.getValue("id")}`);
        },
        sx: {
          cursor: "pointer",
        },
      };
    },
    columns,
    data: data?.users || [], //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return <MantineReactTable table={table} />;
};

export default UsersTable;
