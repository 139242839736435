import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RouterOutputs, trpc } from "../../../utils/trpc";
import DateFilterMenuItems from "../../common/DateFilterMenuItems";

type Message = RouterOutputs["admin"]["listMessages"]["messages"][0];

const MessagesTable = () => {
  const navigate = useNavigate();

  const { data } = trpc.admin.listMessages.useQuery({});

  const columns = useMemo<MRT_ColumnDef<Message>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "createdAt",
        header: "Дата",
        sortingFn: 'datetime',
        accessorFn: ({createdAt}) => new Date(createdAt),
        filterVariant: 'date-range',
        Cell: ({ cell }) => (<>{new Date(cell.getValue<number>()).toLocaleString()}</>),
        renderColumnFilterModeMenuItems: ({ column, internalFilterOptions }) => (
          <DateFilterMenuItems
            column={column}
            internalFilterOptions={internalFilterOptions}
          />
        ),
      },
      {
        accessorKey: "user.fullName",
        header: "Пользователь",
      },
      {
        accessorKey: "user.email",
        header: "Email",
        Cell: ({ cell }) => (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate(`/admin/users/${cell.row.original.userId}`)}
          >
            {cell.getValue<string>()}
          </span>
        ),
      },
      {
        accessorKey: "chatId",
        header: "Идентификатор чата",
      },
      {
        accessorKey: "content",
        header: "Содержимое сообщения",
        enableSorting: false,
        Cell: ({ cell }) => {
          const text = cell.getValue<string>();
          const maxMessageLength = 500;

          return (
            <>
              {text.length > maxMessageLength ? `${text.slice(0, maxMessageLength)}...` : text}
            </>
          );
        },
      },
      {
        accessorKey: "attachedFilename",
        header: "Имя прикрепленного файла",
      },
    ],
    [navigate]
  );

  const table = useMantineReactTable({
    columns,
    data: data?.messages || [],
    enableColumnFilterModes: true,
  });

  return <MantineReactTable table={table} />;
};

export default MessagesTable;
