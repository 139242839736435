import { Paper, PaperProps, Skeleton, Text } from "@mantine/core";
import { observer } from "mobx-react";
import { trpc } from "../../utils/trpc";
import { AdminSettingsContent } from "./AdminSettingsContent";

const PAPER_PROPS: PaperProps = {
  p: "md",
  shadow: "md",
  radius: "md",
  sx: { height: "100%" },
};

const _AdminSettings = () => {
  const { data: configFromServer } = trpc.admin.getConfig.useQuery(undefined, { refetchOnWindowFocus: false });

  return (
    <div>
      <Paper {...PAPER_PROPS}>
        <Text size="lg" fw={600} mb="md">
          Системные настройки
        </Text>
        {!configFromServer && <Skeleton height={100} />}
        {configFromServer && <AdminSettingsContent config={configFromServer.config} />}
      </Paper>
    </div>
  );
};

export const AdminSettings = observer(_AdminSettings);
