import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { Outlet } from "react-router-dom";
import { AuthWrapper } from "../chat/AuthWrapper";
import { AdminLayout } from "./AdminLayout";

export function AdminProviders() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: false,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme,
          colors: {
            brand: [
              "#fff8e1",
              "#ffefcc",
              "#ffde9b",
              "#ffcc64",
              "#ffbd38",
              "#ffb31b",
              "#ffae09",
              "#e39800",
              "#ca8700",
              "#af7300",
            ],
          },
          components: {
            Modal: {
              defaultProps: {
                padding: "xl",
              },
              styles: {
                title: {
                  fontSize: "1.2rem",
                  fontWeight: 600,
                },
              },
            },
            ModalRoot: {
              defaultProps: {
                centered: true,
              },
            },
            Overlay: {
              defaultProps: {
                opacity: 0.6,
                blur: 6,
              },
            },
            // Input: {
            //   defaultProps: {
            //     variant: "filled",
            //   },
            // },
            InputWrapper: {
              styles: {
                label: {
                  marginBottom: 4,
                },
              },
            },
            Code: {
              styles: (theme) => ({
                root: {
                  fontSize: theme.fontSizes.sm,
                  backgroundColor: theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.colors.gray[1],
                },
              }),
            },
          },
          // primaryColor: "brand",
        }}
      >
        <Notifications position="top-center" />
        <AuthWrapper>
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        </AuthWrapper>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
