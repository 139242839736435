import { Button, Container, Stack, Text, Title } from "@mantine/core";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageItem } from "../components/chat/MessageItem/MessageItem";
import { useChatId } from "../hooks/useChatId";
import { trpc } from "../utils/trpc";

const _SharedChatRoute = () => {
  const chatId = useChatId();

  const [copying, setCopying] = useState(false);
  const navigate = useNavigate();

  if (!chatId) return null;

  const ss = trpc.chat.getSharedChat.useQuery({ sharedChatId: chatId });
  const { mutateAsync: copySharedChat } = trpc.chat.copySharedChat.useMutation();

  if (!ss.data) return null;

  const { title, messages, sharedByUsername, createdAt } = ss.data;

  // Date in format DD/MM/YYYY HH:MM:ss

  const date = dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss");

  return (
    <>
      <Container pt="xl" pb={120}>
        <Stack spacing="xs">
          <Title ta="center" mb={16}>
            {title}
          </Title>
          <Text>
            Поделился: {sharedByUsername} ({date})
          </Text>
          {messages
            .filter((message) => message.role !== "system")
            .map((message, index) => (
              <MessageItem key={message.id} message={message} autoscroll={index === messages.length - 1} />
            ))}
          <Button
            size="md"
            mt={16}
            loading={copying}
            onClick={async () => {
              const { newChatId } = await copySharedChat({ sharedChatId: chatId });

              const url = `/chats/${newChatId}`;
              // Open in new tab
              window.open(url, "_blank");
            }}
          >
            Продолжить диалог
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export const SharedChatRoute = observer(_SharedChatRoute);
