import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { Layout } from "../../layouts/Layout";
import "../../styles/markdown.scss";
import { theme } from "../theme";
import { AuthWrapper } from "./AuthWrapper";

export function ChatProviders() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: false,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          ...theme,
          colorScheme,
        }}
      >
        <AuthWrapper>
          <Notifications position="top-right" />
          <Layout />
        </AuthWrapper>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
