import { Paper } from "@mantine/core";
import { observer } from "mobx-react";
import MessagesTable from "./MessagesTable";

const _AdminMessages = () => {

  return (
    <>
      <Paper p="xl">
        <MessagesTable />
      </Paper>
    </>
  );
};

export const AdminMessages = observer(_AdminMessages);
