import { Button, Modal, Stack, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiKey } from "../../hooks/useApiKey";
import { useChatId } from "../../hooks/useChatId";
import { store } from "../../stores/store";
import { IChat } from "../../stores/types";
import { trpc } from "../../utils/trpc";

interface IProps {
  chat: IChat;
  opened: boolean;
  onClose(): void;
}

export function DeleteChatModal({ chat, opened, onClose }: IProps) {
  const [submitting, setSubmitting] = useState(false);

  const [key, setKey] = useApiKey();

  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(key);
  }, [key]);
  const chatId = useChatId();
  const navigate = useNavigate();
  const { mutateAsync: deleteChat } = trpc.chat.deleteChat.useMutation();

  return (
    <>
      <Modal opened={opened} onClose={onClose} title="Удалить чат">
        <form
          onSubmit={async (event) => {
            try {
              setSubmitting(true);
              event.preventDefault();
              store.deleteChatById(chat.id);
              store.deleteMessageById(chat.id);
              deleteChat({ chatId: chat.id });
              if (chatId === chat.id) {
                navigate(`/`);
              }
              onClose();

              notifications.show({
                title: "Удалено",
                message: "Чат удалён.",
              });
            } catch (error: any) {
              if (error.toJSON().message === "Network Error") {
                notifications.show({
                  title: "Error",
                  color: "red",
                  message: "No internet connection.",
                });
              } else {
                notifications.show({
                  title: "Error",
                  color: "red",
                  message: "Can't remove chat. Please refresh the page and try again.",
                });
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          <Stack>
            <Text size="sm">Вы уверены что хотите удалить этот чат?</Text>
            <Button type="submit" color="red" loading={submitting}>
              Удалить
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
