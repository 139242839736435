import { FC } from 'react';
import { Menu } from '@mantine/core';
import { MRT_Column, MRT_InternalFilterOption } from 'mantine-react-table';
import dayjs from 'dayjs';

interface DateFilterMenuItemsProp {
  column: MRT_Column<any>,
  internalFilterOptions: MRT_InternalFilterOption[],
}

//  for correct use, need to add enableColumnFilterModes option to useMantineReactTable props
const DateFilterMenuItems: FC<DateFilterMenuItemsProp> = ({ column, internalFilterOptions }) => {  
  if (!internalFilterOptions.some((opt) => opt.option === 'between' || opt.option === "betweenInclusive")) {
    console.error('Column should have between or betweenInclusive filter option');
    return <></>;
  }

  const datePresets = [
    {
      label: 'Последние 7 дней',
      getRange: () => ({
        start: dayjs().subtract(7, 'day').startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }),
    },
    {
      label: 'Последние 30 дней',
      getRange: () => ({
        start: dayjs().subtract(30, 'day').startOf('day').toDate(),
        end: dayjs().endOf('day').toDate(),
      }),
    },
    {
      label: 'Текущий месяц',
      getRange: () => ({
        start: dayjs().startOf('month').toDate(),
        end: dayjs().endOf('day').toDate(),
      }),
    },
    {
      label: 'Очистить',
      getRange: () => ({
        start: null,
        end: null,
      }),
    },
  ]

  return (
    <>
      {datePresets.map(preset => (
        <Menu.Item
          key={preset.label}
          onClick={() => {
            const { start, end } = preset.getRange();
            column.setFilterValue([start, end]);
          }}
        >
          {preset.label}
        </Menu.Item>
      ))}
    </>
  );
};

export default DateFilterMenuItems;