import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";
import { ChatContainer } from "../components/chat/ChatContainer";
import { useChatId } from "../hooks/useChatId";
import { store } from "../stores/store";

const _ChatRoute = () => {
  const chatId = useChatId();

  if (!chatId) return null;

  const chat = store.getChatById(chatId);

  if (store.chatsLoaded && !chat) {
    return <Navigate to="/" />;
  }

  if (!chat) return null;

  return <ChatContainer chatId={chatId} />;
};

export const ChatRoute = observer(_ChatRoute);
