import { Paper } from "@mantine/core";
import { observer } from "mobx-react";
import FilesTable from "./FilesTable";

const _AdminFiles = () => {

  return (
    <>
      <Paper p="xl">
        <FilesTable />
      </Paper>
    </>
  );
};

export const AdminFiles = observer(_AdminFiles);
