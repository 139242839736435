import axios from "axios";

export const sendImageModelChangeMetrics = async (prevImageModel: string | null, newImageModel: string) => {
  if (prevImageModel !== null && newImageModel !== prevImageModel) {
    try {
      return axios({
        method: 'post',
        url: `${import.meta.env.VITE_BACKEND_ENDPOINT}/api/metrics/changeImageModel`,
        data: {
          prevImageModel,
          newImageModel,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
    } catch (error) {
      console.error('Error sending metric:', error);
    }
  }
}
