import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../utils/trpc";

export function InitTxGlobalAuth() {
  const { mutateAsync: logout } = trpc.auth.logout.useMutation();

  useEffect(() => {
    const initializeAuth = async () => {
      if (!import.meta.env.VITE_FEATURE_FLAG_GLOBAL_AUTH) return;
      try {
        await setupTxGlobalAuth();
      } catch (error) {
        console.error("Error initializing authentication:", error);
      }
    };

    initializeAuth();
  }, []);

  const setupTxGlobalAuth = async () => {
    window.TxGlobalAuth.init({
      env: import.meta.env.VITE_GLOBAL_AUTH_ENV || "dev",
      appName: "chatai",
      appVersion: import.meta.env.VITE_GLOBAL_AUTH_APP_VERSION || "1.0.0",
    }).then(() => {
      window.TxGlobalAuth.subscribeJWT(handleJWTResponse);
    });
  };

  const handleJWTResponse = async (response: { token: string }) => {
    if (response) {
      await syncJwtToChatai(response.token);
    } else {
      try {
        handleLogout();
      } catch (err) {
        console.error(err);
        notifications.show({
          title: "Ошибка",
          message: "Произошла ошибка при выходе",
          color: "red",
        });
      }
    }
  };

  const handleLogout = async () => {
    // не реагируем на то, что пришел null - ориентируемся на session с backend
    await window.TxGlobalAuth.logout();
  };

  const syncJwtToChatai = async (jwtToken: string) => {
    try {
      //
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleAuthError = (error: any) => {
    toast.error(error || "Произошла ошибка", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.error("TXGlobalAuth login error", error);
  };

  return null;
}
