import { ChatType } from "../stores/store";
import chatsBannersJson from './chatsBanners.json';
import helpUrlsJson from './helpUrls.json';
import { IChatBannerConfig } from "./types";

const bigDocSearchSupportedFiles: string[] = (import.meta.env.VITE_BIG_DOC_SEARCH_SUPPORTED_FILES || "application/pdf")
  .split(",").map((word: string) => word.trim());

export const supportedFileTypes: Record<ChatType, string[]> = {
  GENERIC: [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/json",
    "text/csv",
    "text/markdown",
    "application/yaml",
    "application/xml",
  ],
  AI_CONTEXT: bigDocSearchSupportedFiles,
  FLOW: [],
  AI_CODER: [],
  AI_LOCAL: [],
  ANONYMIZER: [],
};

export const chatsHelpUrls: Record<string, string> = helpUrlsJson;
export const chatsBannersConfig: Record<ChatType, IChatBannerConfig> = chatsBannersJson;
