import { Box, Card, Flex, Loader, ThemeIcon } from "@mantine/core";
import { IconPaperclip, IconUser } from "@tabler/icons-react";
import { observer } from "mobx-react";
import FinamLogo2 from "../../../icons/FinamLogo2";
import { IMessage } from "../../../stores/types";

import { MessageContent } from "./MessageContent";
import { MessageTools } from "./MessageTools";
import { RichContent } from "./RichContent/RichContent";

function ToolProcessingPlaceholder({ toolName }: { toolName: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Loader color="yellow" size="sm" style={{ marginRight: 8 }} /> {toolName}
    </div>
  );
}

function _MessageItem({ message, autoscroll }: { message: IMessage; autoscroll?: boolean }) {
  if (message.hidden) {
    return null;
  }
  return (
    <Card withBorder>
      <Flex gap="sm">
        {message.role === "user" && (
          <ThemeIcon color="gray" size="lg">
            <IconUser size={20} />
          </ThemeIcon>
        )}
        {message.role === "assistant" && <FinamLogo2 style={{ height: 28, marginTop: 8 }} />}
        <Box sx={{ flex: 1, width: 0 }} className="markdown">
          {message.toolUsed && <ToolProcessingPlaceholder toolName={message.toolUsed} />}
          {message.richContent ? (
            <RichContent content={message.richContent} />
          ) : (
            <MessageContent content={message.content} autoscroll={autoscroll} />
          )}

          {message.attachedFiles?.map((x) => (
            <div
              key={x.fileId}
              style={{
                alignSelf: "end",
                display: "inline-flex",
                height: "2.25rem",
                border: "1px solid gray",
                borderRadius: 4,
                padding: "0px 16px",
                alignItems: "center",
                margin: 4,
              }}
            >
              <IconPaperclip />
              <div style={{ marginLeft: 8, display: "inline-block" }}>{x.filename}</div>
            </div>
          ))}
        </Box>

        <MessageTools message={message} />
      </Flex>
    </Card>
  );
}

export const MessageItem = observer(_MessageItem);
