import { Button, rem } from "@mantine/core";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { FormEventHandler, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FinamLogo2 from "../../icons/FinamLogo2";
import { Session, store } from "../../stores/store";
import { client, trpc } from "../../utils/trpc";
import styles from "./LoginPage.module.scss";

function _VerifyPage() {
  const navigate = useNavigate();

  // email from query params
  const email = new URLSearchParams(window.location.search).get("email") || "";
  const shouldFillInfo = new URLSearchParams(window.location.search).get("needinfo") === "1";

  const [otpCode, setOtpCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { mutateAsync: verifyOtp } = trpc.auth.verifyOtp.useMutation();
  const [isLoading, setLoading] = useState(false);

  const handleOtpSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Call an API endpoint to verify the OTP
    try {
      const data = await verifyOtp({ email, otpCode, ...(shouldFillInfo ? { firstName, lastName } : {}) });

      if (data.success) {
        // put data.token to localStorage
        localStorage.setItem("token", data.token!);
        store.setAuthorized(true);

        const resp = await client.auth.session.query();
        store.setSession(
          new Session({
            email: resp.session.email,
            isAdmin: resp.session.isAdmin,
            needFillInfo: resp.session.needFillInfo,
            userId: resp.session.userId,
          })
        );
        toast.success("Вы успешно вошли", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          navigate("/");
        }, 1500);
        // OTP is correct, proceed to log the user in
        // You might need to use your authentication logic here
      } else {
        setLoading(false);
        toast.error(data.error || "Произошла ошибка", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Handle the case where the OTP is incorrect
      }
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      toast.error("Произошла ошибка", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <div style={{ height: "100%", width: "100%", display: "flex" }}>
        <ToastContainer />
        <div className={styles.loginPage}>
          <div style={{ flexGrow: 1 }}></div>
          <div className={clsx("mx-auto", styles.loginPageWrapper)}>
            <div style={{ margin: "0 auto" }}>
              <Link to="/">
                <FinamLogo2 width={200} />
              </Link>
            </div>
            <div className={clsx("flex", "flex-col", "space-y-8")}>
              <h1 className={styles.title}>{shouldFillInfo ? "Регистрация" : "Вход"}</h1>
              <>
                <form onSubmit={handleOtpSubmit}>
                  {shouldFillInfo && (
                    <>
                      <input
                        required
                        type="text"
                        name="firstName"
                        className={styles.emailInput}
                        placeholder="Имя"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        required
                        type="text"
                        name="lastName"
                        className={styles.emailInput}
                        placeholder="Фамилия"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </>
                  )}
                  <p className={styles.caption}>
                    На вашу почту был отправлен код для входа, пожалуйста введите его ниже
                  </p>
                  <input
                    required
                    type="text"
                    id="otp"
                    name="otp"
                    className={styles.emailInput}
                    placeholder="Введите код"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value.replace(/\D/g, ""))}
                  />
                  <Button
                    fullWidth
                    loading={isLoading}
                    type="submit"
                    styles={(theme) => ({
                      root: {
                        fontSize: 20,
                        backgroundColor: "#ff7464",
                        height: rem(48),
                        "&:not([data-disabled])": theme.fn.hover({
                          backgroundColor: theme.fn.darken("#ff7464", 0.05),
                        }),
                      },
                    })}
                  >
                    Войти
                  </Button>
                </form>
              </>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}></div>
        </div>
      </div>
    </>
  );
}

export const VerifyPage = observer(_VerifyPage);
