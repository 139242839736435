import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { store } from "../../stores/store";
import { IChat } from "../../stores/types";
import { trpc } from "../../utils/trpc";

interface IProps {
  chat: IChat;
  opened: boolean;
  onClose(): void;
}

export function EditChatModal({ chat, opened, onClose }: IProps) {
  const [submitting, setSubmitting] = useState(false);

  const [value, setValue] = useState("");
  const { mutateAsync: renameChat } = trpc.chat.renameChat.useMutation();

  useEffect(() => {
    if (opened) {
      setValue(chat?.description ?? "");
    }
  }, [opened, chat]);

  return (
    <>
      <Modal opened={opened} onClose={onClose} title="Переименовать чат" withinPortal>
        <form
          onSubmit={async (event) => {
            try {
              setSubmitting(true);
              event.preventDefault();
              store.chats.find((c) => c.id === chat.id)?.setState({ description: value });
              renameChat({ chatId: chat.id, title: value });
              notifications.show({
                title: "Сохранено",
                message: "",
              });
              onClose();
            } catch (error: any) {
              if (error.toJSON().message === "Network Error") {
                notifications.show({
                  title: "Error",
                  color: "red",
                  message: "No internet connection.",
                });
              }
              const message = error.response?.data?.error?.message;
              if (message) {
                notifications.show({
                  title: "Error",
                  color: "red",
                  message,
                });
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          <Stack>
            <TextInput
              label="Название"
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
              formNoValidate
              data-autofocus
            />
            <Button type="submit" loading={submitting}>
              Сохранить
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
