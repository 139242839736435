import { IconChecklist } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { BasicBlock } from "./BasicBlock";
import styles from "./MessageBlock.module.css";
import { ITaskSnippet } from "./types";

const _TaskBlock = ({ snippet }: { snippet: ITaskSnippet }) => {
  const title = snippet.title || "Задача";

  return (
    <BasicBlock
      id={snippet.id}
      snippet={snippet}
      type={snippet.type}
      title={title}
      caption={snippet.caption}
      Icon={IconChecklist}
    >
      {!!snippet.data.project && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Проект</div>
          <div className="section-content">{snippet.data.project}</div>
        </div>
      )}
      {!!snippet.data.content && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Содержание</div>
          <div className="section-content">{snippet.data.content}</div>
        </div>
      )}
    </BasicBlock>
  );
};

export const TaskBlock = observer(_TaskBlock);
