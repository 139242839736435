import { Button, Checkbox, Modal } from "@mantine/core";
import { useState } from "react";

interface PollModalProps {
  open: boolean;
  onClose: () => void;
}

export function PollModal(props: PollModalProps) {
  const [pollChecked, setPollChecked] = useState(false);
  const { open, onClose } = props;

  return (
    <Modal title="Опрос" size="xl" opened={open} onClose={() => {}} withCloseButton={false}>
      <p>Коллега, привет!</p>
      <p>
        Большое спасибо за использование платформы, надеемся, что она тебе помогает в работе и позволяет экономить
        время. Пожалуйста пройди небольшой опрос, твои ответы помогут сделать платформу Финам.AI лучше.
      </p>
      <p>
        Пройти опрос можно по следующей ссылке:{" "}
        <a href="https://finam.simpoll.ru/345f9ec5" target="_blank">
          https://finam.simpoll.ru/345f9ec5
        </a>
      </p>
      <Checkbox
        mb={16}
        label="Я прошёл(а) опрос"
        checked={pollChecked}
        onChange={(event) => {
          setPollChecked(event.currentTarget.checked);
        }}
      />
      <Button
        disabled={!pollChecked}
        onClick={() => {
          // Save to local storage
          localStorage.setItem("poll_2", "true");
          onClose();
        }}
      >
        Закрыть
      </Button>
    </Modal>
  );
}
