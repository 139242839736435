import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { observer } from "mobx-react";
import { AuthWrapper } from "../components/chat/AuthWrapper";
import { theme } from "../components/theme";
import { SharedChatRoute } from "./SharedChatRoute";

interface IProps {
  children: React.ReactNode;
}
const _SharedChatPage = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: false,
  });
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={() => {}}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        withCSSVariables
        theme={{
          ...theme,
          colorScheme,
        }}
      >
        <AuthWrapper>
          <Notifications position="top-right" />
          <SharedChatRoute />
        </AuthWrapper>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export const SharedChatPage = observer(_SharedChatPage);
