interface IAPIError {
  code: string;
  message: string;
}

export class APIError extends Error {
  code: string;

  constructor(err: IAPIError) {
    super(err.message);
    this.name = "APIError";
    this.code = err.code;
  }
}
