import { Alert, Box } from "@mantine/core";
import { chatsBannersConfig } from "../../config/Config";
import { ChatType, store } from "../../stores/store";
import { HEADER_HEIGHT } from "./ChatHeader";
import { observer } from "mobx-react";
import { NAVBAR_WIDTH } from "../../layouts/Layout";
import { useChatId } from "../../hooks/useChatId";

const _ChatsBanner = () => {
  const chatId = useChatId();
  return(
    <>
      {store.textModel && Object.keys(chatsBannersConfig).includes(store.textModel) && chatsBannersConfig[store.textModel as ChatType].enabled && (
        <div>
          {chatId ? <Box sx={{ height: "30px" }} /> : null}
          <Box
            sx={(theme) => ({
              padding: 0,
              left: 0,
              right: 0,
              position: "fixed",
              zIndex: 2,
              top: HEADER_HEIGHT,
              [`@media (min-width: ${theme.breakpoints.md})`]: {
                left: NAVBAR_WIDTH,
              },
            })}
          >
            <Alert
              sx={{
                width: "100%",
                textAlign: "center",
                maxWidth: "100%",
                margin: 0,
                padding: 0,
                borderRadius: 0,
                '& a': {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }
              }}
              variant="filled"
              color={chatsBannersConfig[store.textModel as ChatType].color
            }>
              <p style={{ margin: 0, fontSize: "16px" }} dangerouslySetInnerHTML={{  __html: chatsBannersConfig[store.textModel as ChatType].text }} />
            </Alert>
          </Box>
        </div>
      )}
    </>
  )
};

export const ChatsBanner = observer(_ChatsBanner);
