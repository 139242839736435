import { ActionIcon, Flex, Menu } from "@mantine/core";
import { IconBrandHipchat, IconCode, IconDotsVertical, IconMessages, IconRipple } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useChatId } from "../../hooks/useChatId";
import { Chat, store } from "../../stores/store";
import { IChat } from "../../stores/types";
import { trpc } from "../../utils/trpc";
import { ShareChatModal } from "../ShareChatModal";
import { DeleteChatModal } from "./DeleteChatModal";
import { EditChatModal } from "./EditChatModal";
import { MainLink } from "./MainLink";
import { IconSpy } from "@tabler/icons-react";

type CustomChatItemProps = {
  active?: boolean;
  customIcon?: React.ReactNode;
  path: string;
  label: string;
};

function CustomChatItem({ active, customIcon, path, label }: CustomChatItemProps) {
  return (
    <Flex
      className={active ? "active" : undefined}
      sx={(theme) => ({
        marginTop: 1,
        "&:hover, &.active": {
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
        },
      })}
    >
      <Link to={path} style={{ flex: 1 }}>
        <MainLink icon={customIcon || <IconMessages size="1rem" />} color="#954435" label={label} />
      </Link>
    </Flex>
  );
}

function ChatItem({ chat, active }: { chat: IChat; active?: boolean }) {
  const [deleteOpened, { open: openDelete, close: closeDelete }] = useDisclosure(false);
  const [shareOpened, { open: openShare, close: closeShare }] = useDisclosure(false);
  const [renameOpened, { open: openRename, close: closeRename }] = useDisclosure(false);

  return (
    <Flex
      key={chat.id}
      className={active ? "active" : undefined}
      sx={(theme) => ({
        marginTop: 1,
        "&:hover, &.active": {
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
        },
      })}
    >
      <Link to={`/chats/${chat.id}`} style={{ flex: 1 }}>
        <MainLink icon={<IconMessages size="1rem" />} color="#954435" label={chat.description} />
      </Link>
      <DeleteChatModal chat={chat} opened={deleteOpened} onClose={closeDelete} />
      <ShareChatModal chat={chat} opened={shareOpened} onClose={closeShare} />
      <EditChatModal chat={chat} opened={renameOpened} onClose={closeRename} />
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon sx={{ height: "auto" }}>
            <IconDotsVertical size={20} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openRename}>Переименовать</Menu.Item>
          <Menu.Item onClick={openShare}>Поделиться</Menu.Item>
          <Menu.Item onClick={openDelete}>Удалить</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
}

function _Chats({ search }: { search: string }) {
  const chatId = useChatId();
  const chats = store.getOrderedChats;
  const filteredChats = useMemo(
    () =>
      (chats ?? []).filter((chat) => {
        if (!search) return true;
        return chat.description.toLowerCase().includes(search);
      }),
    [chats, search]
  );
  const { refetch } = trpc.chat.getChats.useQuery();

  useEffect(() => {
    async function fetchChats() {
      const chats = await refetch();
      if (!chats.data) {
        store.setChatsLoaded(true);
        return;
      }
      for (const rawChat of chats.data) {
        const existingChat = store.getChatById(rawChat.id);
        if (!existingChat) {
          const chat = new Chat({
            id: rawChat.id,
            description: rawChat.title,
            createdAt: new Date(rawChat.createdAt),
            totalTokens: 0,
            updatedAt: new Date(rawChat.updatedAt),
            type: rawChat.type,
            chatLoaded: false,
          });
          store.addChat(chat);
        }
      }
      store.setChatsLoaded(true);
    }
    fetchChats();
  }, []);

  return (
    <>
      {store.appConfig.featureFlags.finamFlow && store.chatsLoaded && (
        <CustomChatItem
          active={chatId === "flow"}
          customIcon={<IconRipple size="1rem"/>}
          path={"/chats/flow"}
          label="Finam Flow"
        />
      )}
      {store.appConfig.featureFlags.aiCoder && store.chatsLoaded && store.session?.isAdmin && (
        <CustomChatItem
          active={chatId === "aicoder"}
          customIcon={<IconCode size="1rem"/>}
          path={"/chats/aicoder"}
          label="AI Coder"
        />
      )}
      {store.appConfig.featureFlags.aiLocal && store.chatsLoaded && store.session?.isAdmin && (
        <CustomChatItem
          active={chatId === "ailocal"}
          customIcon={<IconSpy size="1rem"/>}
          path={"/chats/ailocal"}
          label="AI Local"
        />
      )}
      {store.appConfig.featureFlags.anonymizer && store.chatsLoaded && store.session?.isAdmin && (
        <CustomChatItem
          active={chatId === "anonymizer"}
          customIcon={<IconSpy size="1rem"/>}
          path={"/chats/anonymizer"}
          label="Anonymizer"
        />
      )}
      {filteredChats.map((chat) => (
        <ChatItem key={chat.id} chat={chat} active={chat.id === chatId} />
      ))}
    </>
  );
}

export const Chats = observer(_Chats);
