import { Button, Paper, Space, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { observer } from "mobx-react";
import { trpc } from "../../../utils/trpc";
import UsersTable from "./UsersTable";

const _AdminUsers = () => {
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => null,
    },
  });

  const { mutate: createUser } = trpc.admin.createUser.useMutation();
  const utils = trpc.useUtils();

  return (
    <>
      <Paper p="xl">
        <UsersTable />
        <Space h="md" />
        <form
          onSubmit={form.onSubmit((values) => {
            createUser(
              {
                email: values.email,
              },
              {
                onSuccess: (e) => {
                  if (e.success) {
                    notifications.show({
                      message: "Пользователь создан",
                      color: "green",
                    });
                    utils.admin.listUsers.invalidate();
                    form.reset();
                  } else {
                    notifications.show({
                      message: e.error || "При создании пользователя произошла ошибка",
                      color: "red",
                    });
                  }
                },
                onError: (err) => {
                  notifications.show({
                    message: "При создании пользователя произошла ошибка",
                    color: "red",
                  });
                },
              }
            );
          })}
        >
          <TextInput required label="Email" w={250} type="email" {...form.getInputProps("email")} />
          <Space h="md" />
          <Button type="submit">Создать пользователя</Button>
        </form>
      </Paper>
    </>
  );
};

export const AdminUsers = observer(_AdminUsers);
