import { ActionIcon, Box, CopyButton, Tooltip } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { IMessage } from "../../../stores/types";

const _MessageTools = ({ message }: { message: IMessage }) => {
  const contentPresent = useMemo(() => Boolean(message.content), [message.content]);

  if (!contentPresent) {
    return null;
  }

  return (
    <Box>
      <CopyButton value={message.content!}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? "Скопировано" : "Копировать"} position="left">
            <ActionIcon onClick={copy}>
              <IconCopy opacity={0.5} size={20} />
            </ActionIcon>
          </Tooltip>
        )}
      </CopyButton>
    </Box>
  );
};

export const MessageTools = observer(_MessageTools);
