import { Anchor, Badge, Button, Center, Container, List, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { PromptInput } from "../components/chat/PromptInput/PromptInput";
import FinamLogo2 from "../icons/FinamLogo2";
import { store } from "../stores/store";
import { useEffect } from "react";
import { ChatsBanner } from "../components/chat/ChatsBanner";

export const IndexRoute = observer(() => {
  useEffect(() => {
    store.setImageModel(store.appConfig.imageModels[0].value);
    store.setTextModel("GENERIC");
  }, []);

  return (
    <>
      <ChatsBanner/>
      <Center py="xl" sx={{ height: "100%" }}> 
        <Container
          size="sm"
          sx={{
            overflowY: "hidden",
            overflowX: "hidden",
            paddingBottom: "20%"
          }}
        > 
          <Badge mb="lg">GPT-4</Badge>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 16,
              maxWidth: "100%",
              flexWrap: "wrap",
              gap: 32,
            }}
          >
            <FinamLogo2 style={{ maxWidth: 80, marginRight: 24 }} />
            <span style={{ fontSize: 40, color: "#ffc755" }}>Финам.AI</span>
            <Button component="a" style={{}} href="/docs/FinamAI_Guide.pdf" target="blank">
              Скачать гайд <IconDownload style={{ marginLeft: 8 }} />
            </Button>
          </div>
          <Text mt={4} size="xl" fw={700}>
            Общайся c Финам.AI продуктивно!
          </Text>
            <Text mt={12} size="lg">
              ChatGPT:
            </Text>
            <List>
              <List.Item>
                пиши промпт правильно - Помоги с [тема задачи], 
                твоя роль - [название или описание роли]. Вот описание задачи: 
                [описание здачи, как можно детальнее]. Сделай [укажи, что нужно]. 
                Приведи ответ в формате [желаемый формат: список, таблицей и тд];
              </List.Item>

              <List.Item>
                не понравился ответ - напиши детали, укажи что не понравилось,
                попробуй в новом чате с более детальным описанием вопроса.
              </List.Item>
            </List>
            <Text mt={12} size="lg">
              DesignControl:
            </Text>
            <List>
              <List.Item>
                у бота можно спросить что умеет выбранная стилистика;
              </List.Item>

              <List.Item>
                можно запросить картинки в разном разрешении/соотношении
                сторон и даже в разном количестве;
              </List.Item>
                
              <List.Item>
                если результат с первой попытки не устроил, надо попробовать объяснить,
                что именно не понравилось и как это изменить.
              </List.Item>
            </List>
          <Text mt={12}>
            Есть <Anchor href="https://wiki.finam.ru/pages/viewpage.action?pageId=504072863" target="_blank"> справочник</Anchor>! Хороший промт дает хороший результат!
          </Text>
        </Container>
      </Center>
      <PromptInput />
    </>
  );
});
