import { IconFaceId, IconPhone } from "@tabler/icons-react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { BasicBlock } from "./BasicBlock";
import styles from "./MessageBlock.module.css";
import { IMeetingSnippet } from "./types";

const _MeetingBlock = ({ snippet }: { snippet: IMeetingSnippet }) => {
  const title = snippet.title || snippet.data.title || "Встреча";
  const caption = snippet.caption || dayjs(snippet.data.date).format("DD/MM/YYYY HH:mm");

  return (
    <BasicBlock id={snippet.id} snippet={snippet} type={snippet.type} title={title} caption={caption} Icon={IconPhone}>
      {!!snippet.data.members?.length && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Участники встречи</div>
          <div className={styles.sectionContent}>
            {snippet.data.members.map((member) => (
              <div key={member.id} className={styles.member}>
                {member.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {!!snippet.data.summary && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Краткое содержание</div>
          <div className={styles.sectionContent}>{snippet.data.summary}</div>
        </div>
      )}
      {!!snippet.data.keypoints && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Ключевые моменты</div>
          <div className={styles.sectionContent}>{snippet.data.keypoints}</div>
        </div>
      )}
      {!!snippet.data.actionpoints && (
        <div className={`${styles.meetingSection} ${styles.actionPoints}`}>
          <div className={styles.sectionTitle}>План действий</div>
          <div className={styles.sectionContent}>{snippet.data.actionpoints}</div>
        </div>
      )}
      {!!snippet.data.comments?.length && (
        <div className={styles.meetingSection}>
          <div className={styles.sectionTitle}>Комментарии</div>
          <div className={styles.sectionContent}>
            {snippet.data.comments.map((comment) => (
              <div key={comment.date} className={styles.comment}>
                <div className={styles.commentAuthor}>
                  <IconFaceId />
                  {comment.author.name}
                </div>
                <div className={styles.commentText}>{comment.comment}</div>
                <div className={styles.commentDate}>{dayjs(comment.date).format("DD/MM/YYYY")}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </BasicBlock>
  );
};

export const MeetingBlock = observer(_MeetingBlock);
