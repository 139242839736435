import { Box, Button, MultiSelect, NumberInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { trpc } from "../../utils/trpc";

interface AdminSettingsProps {
  config: any;
}

const _AdminSettingsContent = (props: AdminSettingsProps) => {
  const { mutate: updateConfig, isLoading } = trpc.admin.updateConfig.useMutation();

  const form = useForm({
    initialValues: props.config,
    validate: {
      requestDailyLimit: (value) => null,
      allowedDomains: (value) => null,
      webBrowsingDomainBlacklist: (value) => null,
    },
  });

  useEffect(() => {}, []);
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        updateConfig(
          {
            requestDailyLimit: values.requestDailyLimit,
            allowedDomains: values.allowedDomains,
            inputCharLimit: values.inputCharLimit,
            webBrowsingDomainBlacklist: values.webBrowsingDomainBlacklist,
          },
          {
            onSuccess: () => {
              notifications.show({
                message: "Настройки сохранены",
                color: "green",
              });
              form.resetDirty();
            },
            onError: (err) => {
              notifications.show({
                message: "Ошибка сохранения настроек",
                color: "red",
              });
            },
          }
        );
      })}
    >
      <Stack align="flex-start">
        <NumberInput
          label="Лимит запросов на одного пользователя (в день)"
          {...form.getInputProps("requestDailyLimit")}
        />
        <NumberInput label="Лимит символов в одном запросе" max={120000} {...form.getInputProps("inputCharLimit")} />
        <MultiSelect
          label="Домены почты с которых возможна самостоятельная регистрация"
          data={form.values.allowedDomains}
          placeholder="Например corp.finam.ru"
          searchable
          creatable
          getCreateLabel={(query) => `+ Добавить ${query}`}
          {...form.getInputProps("allowedDomains")}
          onCreate={(query) => {
            form.setFieldValue("allowedDomains", [...form.values.allowedDomains, query]);
            return query;
          }}
        />{" "}
        <MultiSelect
          label="Список запрещённых доменов для функции веб-браузинга"
          data={form.values.webBrowsingDomainBlacklist}
          placeholder="Например vk.com"
          searchable
          creatable
          getCreateLabel={(query) => `+ Добавить ${query}`}
          {...form.getInputProps("webBrowsingDomainBlacklist")}
          onCreate={(query) => {
            form.setFieldValue("webBrowsingDomainBlacklist", [...form.values.webBrowsingDomainBlacklist, query]);
            return query;
          }}
        />
        <Box sx={{ width: "auto" }}>
          <Button type="submit" loading={isLoading} disabled={!form.isDirty()}>
            Сохранить
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export const AdminSettingsContent = observer(_AdminSettingsContent);
