import { LoadingOverlay } from "@mantine/core";

interface IProps {
  children: React.ReactNode;
  loading: boolean;
}

export default function LoadingScreen(props: IProps) {
  const { children, loading } = props;

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: 1000,
          pointerEvents: loading ? undefined : "none",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: 1000,
            pointerEvents: loading ? undefined : "none",
          }}
        >
          <LoadingOverlay visible={loading} zIndex={1000} transitionDuration={200} exitTransitionDuration={200} />
        </div>
      </div>
      {!loading && <>{children}</>}
    </>
  );
}
